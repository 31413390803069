import Vue from 'vue';
import Vuex from 'vuex';
import {helper, router} from '../_helpers';
import {defaultSettings} from '../_helpers/defaultSettings';
import moment from "moment";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        pageTitle: 'Home',
        loading: false,
        user: null,
        unMute: defaultSettings.unMute,
        message: {
            type: null,
            body: null
        },
        requestGridsOptions: {
            options: {
                sortBy: ['id'],
                sortDesc: [true]
            },
            filter: {
                createdAtDates: moment().format("YYYY-MM-DD")
            }
        },
        appointmentsGridsOptions: {
            options: {
                sortBy: ['id'],
                sortDesc: [true]
            },
            filter: {},
        },
        reportGridsOptions: {
            options: {
                sortBy: ['id'],
                sortDesc: [true]
            },
            filter: {
                reportType: "OPERATOR",
                request: {}
            },
        },
        stompClient: null
    },
    mutations: {
        setPageTitle(state, data) {
            state.pageTitle = data
        },
        setStompClint(state, stompClient) {
            state.stompClient = stompClient
        },
        showMessage(state, type, body) {
            state.message = {type, body}
        },
        setAuth(state, user) {
            state.user = user;
            helper.ls.set('user', user);
        },
        unSetAuth(state) {
            state.user = null;
            state.stompClient.stomp.disconnect();
            state.stompClient = null;
            helper.ls.remove('user');
            router.push('login');
        },
        setRequestGridsOptions(state, options) {
            state.requestGridsOptions = options;
        },
        setAppointmentsGridsOptions(state, options) {
            state.appointmentsGridsOptions = options;
        },
        setReportGridsOptions(state, options) {
            state.reportGridsOptions = options;
        },
        setLoading(state, loading) {
            state.loading = loading;
        },
        setUnMute(state, unMute) {
            state.unMute = unMute;
            helper.ls.set("settings_unmute", unMute);
        }
    },
    actions: {
        checkPageTitle({commit}, title) {
            commit('setPageTitle', title);
        },
        auth({commit}, user) {
            commit('setAuth', user)
        },
        stompConnect({commit}, stompClient) {
            commit('setStompClint', stompClient)
        },
        logout({commit}) {
            commit('unSetAuth')
        },
        checkAuth({commit}) {
            if (helper.ls.get('user')) {
                commit('setAuth', helper.ls.get('user'))
            }
        },
        setUnMute({commit}, unMute) {
            commit('setUnMute', unMute)
        },
        // eslint-disable-next-line no-unused-vars
        newRequestReceived({commit}, request) {
            //used only for subscribes purposes on this action from components
        },
        // eslint-disable-next-line no-unused-vars
        remindReceived({commit}, remind) {
            //used only for subscribes purposes on this action from components
        },
        // eslint-disable-next-line no-unused-vars
        updateAccountReceived({commit}, request) {
            //used only for subscribes purposes on this action from components
        },
        loading({commit}, loading) {
            commit('setLoading', loading)
        },
        updateRequestGridOptions({commit}, options) {
            commit('setRequestGridsOptions', options)
        },
        updateAppointmentsGridOptions({commit}, options) {
            commit('setAppointmentsGridsOptions', options)
        },
        updateReportGridOptions({commit}, options) {
            commit('setReportGridsOptions', options)
        },
    }
});

export default store;
