import Vue from 'vue'
import {axiosInstance} from "./axiosInstance";

export const http = {

    get(url) {
        return new Promise((resolve, reject) => {
            axiosInstance.get(url).then(
                (response) => resolve(response.data)
            ).catch(error => {
                reject(error)
            })
        });
    },

    post(url, form) {
        if (!form) {
            return new Promise((resolve, reject) => {
                axiosInstance.post(url).then(
                    (response) => resolve(response.data)
                ).catch(error => {
                    reject(error)
                })
            });
        }
        return this.sendForm(url, form, 'post');
    },

    put(url, form) {
        return this.sendForm(url, form, 'put');
    },

    delete(url) {
        return new Promise((resolve, reject) => {
            axiosInstance.delete(url).then(
                (response) => resolve(response.data)
            ).catch(error => {
                reject(error)
            })
        });
    },

    sendForm(url, form, method) {
        form.startProcessing();
        return new Promise((resolve, reject) => {
            axiosInstance.request({
                method: method,
                url: url,
                data: form.data()
            }).then(response => {
                form.finishProcessing();
                resolve(response);
            }).catch(error => {
                form.busy = false;
                if (error.response) {
                    form.errors.set(form.extractErrors(error.response));
                }
                reject(error)
            })
        });
    }
};

Vue.prototype.$http = http;
