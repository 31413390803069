<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn color="green" v-show="request.isPublishedOnSite" v-on="on">
                Добавить ответ
            </v-btn>
            <QuestionPublishDialog :id="id" :request="request" :areas="areas" @saved="onQuestionPublished"></QuestionPublishDialog>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Добавить ответ</span>
            </v-card-title>
            <v-card-text>
                <v-textarea v-model="form.text" label="Ответ"></v-textarea>
                <v-alert v-if="form.errors.has('text')" dense outlined type="error" :icon="false">
                    {{form.errors.get('text') }}
                </v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Отмена</v-btn>
                <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Form from "../../_helpers/_form/Form";
    import RequestService from "../../_services/RequestService";
    import QuestionPublishDialog from "./QuestionPublishDialog";

    export default {
        name: "AnswerDialog",
        components: {QuestionPublishDialog},
        props: {
            id: Number,
            request: Object,
            areas: Array
        },
        data() {
            return {
                form: new Form({}),
                dialog: false
            }
        },
        methods: {
            save() {
                RequestService.addAnswer(this.id, this.form).then(() => {
                    this.dialog = false;
                    this.$emit('saved');
                });
            },
            onQuestionPublished() {
                this.request.isPublishedOnSite = true;
            }
        }
    }
</script>

<style scoped>

</style>
