import {http} from "../_helpers";
import Service from "./Service";

class AccountService extends Service {

    getMyAccount() {
        return http.get(`/accounts/my`);
    }

    getAllAccounts() {
        return http.get(`/accounts`);
    }
}

export default new AccountService;
