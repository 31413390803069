import axios from 'axios'
import {helper} from "./helper";
import {router} from "./router";

export const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    timeout: process.env.VUE_APP_HTTP_TIMEOUT
});

axiosInstance.interceptors.request.use(function (request) {
    if (helper.ls.get('user')) {
        request.headers = {'Authorization': 'Bearer ' + helper.ls.get('user').token};
    }
    return request
}, function (error) {
    if (process.env.VUE_APP_DEBUG_HTTP === 'true') {
        console.log(
            '>>>', error
        )
    }
    return Promise.reject(error)
});

axiosInstance.interceptors.response.use(function (response) {
    const request = response.config;
    if (process.env.VUE_APP_DEBUG_HTTP === 'true') {
        console.log(
            '>>>', request.method.toUpperCase(), request.url, request.params,
            '\n   ', response.status, response.data
        )
    }
    return response
}, function (error) {
    let {response, config: request} = error;
    if (process.env.VUE_APP_DEBUG_HTTP === 'true') {
        if (request) {
            console.log(
                '>>>', request.method.toUpperCase(), request.url, request.params,
                '\n   ', response.status, response.data
            )
        }
    }
    if (response.status === 401 || response.headers['token-expired']) {
        helper.ls.remove('user');
        router.push('/login');
    }
    return Promise.reject(error)
});

