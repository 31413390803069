<template>
    <div>
        <v-list dense>
            <v-list-item>
                <v-list-item-content>
                    <v-switch :prepend-icon="muteIcon" v-model="unMute"></v-switch>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </div>
</template>

<script>

    export default {
        name: "Settings",
        computed: {
            unMute: {
                get() {
                    return this.$store.state.unMute;
                },
                set(value) {
                    this.$store.dispatch('setUnMute', value)
                }
            },
            muteIcon: function() {
                return this.unMute ? "mdi-volume-high" : "mdi-volume-off"
            }
        }
    }
</script>

<style scoped>

</style>
