<template>
    <div>
        <v-card-title>
            Список сотрудников
            <v-spacer></v-spacer>
            <div>
                <v-btn large color="green" to="/users/create">
                    Добавить сотрудника
                </v-btn>
            </div>
        </v-card-title>
        <v-data-table class="elevation-1"
                      :headers="headers"
                      :items="users"
                      :options.sync="options"
                      :server-items-length="totalElements"
                      item-key="id"
                      :loading="loading"
                      loading-text="Загрузка ..."
                      :footer-props="{'items-per-page-options':[20, 30, 50]}"
        >
            <template v-slot:body="{ items }">
                <tbody>
                <tr>
                    <td>
                        <v-text-field v-model="filter.id" type="text"></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="filter.firstName" type="text"></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="filter.lastName" type="text"></v-text-field>
                    </td>
                    <td>
                        <v-select v-model="filter.role" :items="roles" clearable></v-select>
                    </td>
                </tr>
                <tr v-for="item in items" :key="item.id">
                    <td width="60">{{ item.id }}</td>
                    <td width="60">{{ item.firstName }}</td>
                    <td width="60">{{ item.lastName }}</td>
                    <td width="60">{{ item.roleDesc }}</td>
                    <td width="60">
                        <v-icon small @click="$router.push(`/users/${item.id}/edit`)">mdi-pencil</v-icon>
                        <v-dialog v-model="deleteDialog[item.id]" max-width="320">
                            <template v-slot:activator="{ on }">
                                <v-icon class="ml-4" small color="red" v-on="on">mdi-delete</v-icon>
                            </template>
                            <v-card>
                                <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="deleteDialog[item.id] = false">Отмена</v-btn>
                                    <v-btn color="red" text @click="deleteItem(item.id)">Удалить</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import UserService from "../_services/UserService";

    export default {
        name: "UsersGrid",
        data() {
            return {
                headers: [
                    {text: 'ID', value: 'id'},
                    {text: 'Имя', value: 'firstName'},
                    {text: 'Фамилия', value: 'lastName'},
                    {text: 'Роль', value: 'role'},
                ],
                users: [],
                roles: [],
                loading: true,
                options: {
                    sortBy: ['lastName']
                },
                totalElements: 0,
                filter: {},
                deleteDialog: {}
            }
        },
        watch: {
            options: {
                handler() {
                    this.fetchGrid();
                },
                deep: true
            },
            filter: {
                handler() {
                    this.fetchGrid();
                },
                deep: true
            },
        },
        methods: {
            fetch() {
                this.fetchRoles();
            },
            fetchRoles() {
                UserService.getRoles().then(data => {
                    this.roles = data;
                });
            },
            fetchGrid() {
                this.loading = true;
                UserService.getAll(this.options, this.filter).then(data => {
                    this.users = data.content;
                    this.totalElements = data.totalElements;
                    this.loading = false;
                });
            },
            deleteItem(id) {
                UserService.delete(id).then(() => {
                    this.fetchGrid();
                });
            }
        },
        created() {
            this.fetch();
            this.$store.dispatch('loading', false);
        },
        beforeCreate() {
            this.$store.dispatch('loading', true);
        }
    };
</script>
