<template>
    <div>
        <v-card-title v-if="!title">
            База заявок
            <v-btn icon @click="$emit('forceUpdate')">
                <v-icon>mdi-reload</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div>
                <v-btn large color="green" to="/requests/create">
                    Добавить заявку
                </v-btn>
            </div>
        </v-card-title>
        <v-card-title v-if="title">
            {{title}}
        </v-card-title>
        <v-data-table class="elevation-1"
                      :headers="headers"
                      :items="requests"
                      :options.sync="requestGridsOptions.options"
                      :server-items-length="totalElements"
                      show-select
                      single-select
                      v-model="selected"
                      item-key="id"
                      :loading="loading"
                      loading-text="Загрузка ..."
                      :footer-props="{'items-per-page-options': itemPerPage}"
                      :fixed-header="fixedHeader"
                      :height="tableHeight"
        >
            <template v-slot:body="{ items }">
                <tbody>
                <tr v-if="!filter" class="row_sticky">
                    <td width="20" class="px-2"></td>
                    <td width="20" class="px-2"></td>
                    <td width="60">
                        <v-text-field v-model="requestGridsOptions.filter.id" type="text"></v-text-field>
                    </td>
                    <td width="80">
                        <v-select v-model="requestGridsOptions.filter.state" :items="states" clearable></v-select>
                    </td>
                    <td v-if="isVisibleColumn('source')" width="180">
                        <v-select v-model="requestGridsOptions.filter.source" item-value="id" item-text="name" :items="sources" clearable></v-select>
                    </td>
                    <td width="120">
                        <v-text-field v-model="requestGridsOptions.filter.cleanPhone" type="text"></v-text-field>
                    </td>
                    <td width="120">
                        <v-dialog ref="dialog"
                                  v-model="dialog.createdAt.modal"
                                  :return-value.sync="requestGridsOptions.filter.createdAtDates"
                                  persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        v-model="requestGridsOptions.filter.createdAtDates"
                                        prepend-icon="mdi-calendar-month-outline"
                                        readonly
                                        clearable
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dialog.createdAt.model"
                                           type="date"
                                           scrollable
                                           range
                                           first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialog.createdAt.modal = false">Отмена</v-btn>
                                <v-btn text color="primary" @click="$refs.dialog.save(dialog.createdAt.model)">
                                    Выбрать
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </td>
                    <td width="120">
                        <v-dialog ref="dialog2"
                                  v-model="dialog.assignedAt.modal"
                                  :return-value.sync="requestGridsOptions.filter.assignedAtDate"
                                  persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        v-model="requestGridsOptions.filter.assignedAtDate"
                                        prepend-icon="mdi-calendar-month-outline"
                                        readonly
                                        clearable
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dialog.assignedAt.model" type="date" scrollable
                                           first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialog.assignedAt.modal = false">Отмена</v-btn>
                                <v-btn text color="primary" @click="$refs.dialog2.save(dialog.assignedAt.model)">
                                    Выбрать
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </td>
                    <td v-if="isVisibleColumn('operator')" width="160">
                        <v-select v-model="requestGridsOptions.filter.operator" :items="operators" clearable
                                  item-value="id"
                                  item-text="fullName"></v-select>
                    </td>
                    <td v-if="isVisibleColumn('userName')" width="160">
                        <v-text-field v-model="requestGridsOptions.filter.userName" type="text"></v-text-field>
                    </td>
                    <td v-if="isVisibleColumn('subdivision')" width="160">
                        <v-select v-model="requestGridsOptions.filter.subdivision" :items="subdivisions" clearable
                                  item-value="id"
                                  item-text="name"></v-select>
                    </td>
                </tr>
                <tr v-for="(item, key) in items" :key="item.id" v-bind:class="{'grey darken-3': isHighlighted(item)}">
                    <td class="pr-0">
                        <v-checkbox v-if="$helper.permission.canDeleteRequest(item, user)" v-model="selected" :value="item.id" hide-details class="ma-0"/>
                    </td>
                    <td class="px-2">
                        <v-dialog v-model="deleteDialog[item.id]" max-width="320"
                                  v-if="$helper.permission.canDeleteRequest(item, user)">
                            <template v-slot:activator="{ on }">
                                <v-icon small color="red" v-on="on">mdi-delete</v-icon>
                            </template>
                            <v-card>
                                <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn text @click="deleteDialog[item.id] = false">Отмена</v-btn>
                                    <v-btn color="red" text @click="deleteItem(item.id)">Удалить</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </td>
                    <td>
                        <a @click="editRequest(item.id, items[key-1], items[key + 1])">{{ item.id }}</a>
                        <span v-if="item.isPublishedOnSite" style="position: relative"><v-icon small color="green" title="Опубликован на сайте" style="position: absolute; left: 10px;">mdi-share-outline</v-icon></span>
                    </td>
                    <td>
                        <v-chip :color="item.state.chipColor" outlined dark>
                            <v-icon v-if="item.appointmentApproved" color="green" small left>mdi-check</v-icon>
                            <v-icon v-if="item.callState === 'APPEARED'" color="blue" small left>mdi-phone-forward</v-icon>
                            <v-icon v-if="item.callState === 'CONNECTED'" color="green" small left>mdi-phone-check</v-icon>
                            <v-icon v-if="item.callState === 'ON_HOLD'" color="orange" small left>mdi-phone-lock</v-icon>
                            <v-icon v-if="item.callState === 'MISSED'" color="red" small left>mdi-phone-missed</v-icon>
                            {{item.state.text}}
                        </v-chip>
                    </td>
                    <td v-if="isVisibleColumn('source')">
                        <div v-if="item.source">
                            {{ item.source.name }}
                            <span v-if="item.isJivo === true">(Jivo)</span>
                            <span v-if="item.additionalInfo"> ({{item.additionalInfo}})</span>
                        </div>
                    </td>
                    <td>
                        {{item.userPhone}}
                        <span v-if="item.isDouble === true && 1 === 0" style="padding-left: 10px">
                            <v-icon>mdi-repeat-once</v-icon>
                        </span>
                    </td>
                    <td>{{ $moment(item.createdAt).dateTimeFormat() }}</td>
                    <td>{{ $moment(item.assignedAt).dateTimeFormat() }}</td>
                    <td v-if="isVisibleColumn('operator')">
                        <div v-if="item.operator">{{ item.operator.fullName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('userName')">
                        <div v-if="item.userName">{{ item.userName }}</div>
                    </td>
                    <td v-if="isVisibleColumn('subdivision')">
                        <div v-if="item.subdivision">{{ item.subdivision.name }}</div>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-data-table>
        <div class="table-footer-prepend d-flex pl-3 align-center">
            <div v-if="selected.length">
                <v-dialog v-model="deleteBulkDialog" max-width="320" v-if="$helper.permission.canDeleteBulkRequest(user)">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="error" outlined small>
                            <v-icon x-small class="mr-2">mdi-delete</v-icon> Удалить выбранные ({{selected.length}})
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-text class="text-center pt-7">Удалить выбранные элементы?</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="deleteBulkDialog = false">Отмена</v-btn>
                            <v-btn color="red" text @click="deleteSelected">Удалить</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-btn color="primary" class="ml-3" outlined small @click="selected = []">
                    <v-icon x-small class="mr-2">mdi-cancel</v-icon> Отменить выбор
                </v-btn>
            </div>
        </div>

        <v-bottom-sheet v-model="dialog.requestEdit.modal" fullscreen scrollable persistent>
            <v-card style="background: none">
                <v-card-text style="height: 100vh;">
                    <RequestUpdatePage v-if="editedRequest" :id="editedRequest" :nextRequest="nextRequest" :prevRequest="prevRequest" :key="editedRequestKey" @changeEdited="changeEdited" @closeAndUpdate="closeEditAndFetch" @closeEdit="closeEdit"></RequestUpdatePage>
                </v-card-text>
            </v-card>
        </v-bottom-sheet>
    </div>
</template>

<script>
    import RequestService from "../_services/RequestService";
    import {mapState} from 'vuex';
    import _ from "lodash";
    import SourceService from "../_services/SourceService";
    import UserService from "../_services/UserService";
    import SubdivisionService from "../_services/SubdivisionService";
    import RequestUpdatePage from "./RequestUpdatePage";

    export default {
        name: 'RequestGrid',
        components: {RequestUpdatePage},
        props: {
            filter: Object,
            title: String,
            exclude: Array
        },
        data: function() {
            return {
                requests: [],
                states: [],
                sources: [],
                operators: [],
                subdivisions: [],
                selected: [],
                loading: true,
                totalElements: 0,
                dialog: {
                    createdAt: {
                        model: [],
                        modal: false
                    },
                    assignedAt: {
                        model: null,
                        modal: false
                    },
                    requestEdit: {
                        modal: false
                    }
                },
                deleteDialog: {},
                deleteBulkDialog: false,
                tableHeight:  !this.filter ? this.calcTableHeight() : null,
                fixedHeader: !this.filter,
                requestGridsOptions: !this.filter ? this.$store.state.requestGridsOptions :
                    {
                        options: {
                            sortBy: ['id'],
                            sortDesc: [true]
                        },
                        filter: this.filter
                    },
                editedRequest: null,
                nextRequest: null,
                prevRequest: null,
                editedRequestKey: 0
            }
        },
        watch: {
            requestGridsOptions: {
                handler() {
                    this.fetchGrid();
                },
                deep: true
            }
        },
        methods: {
            fetch() {
                if (this.filter) {
                    //this.fetchGrid();
                } else {
                    this.fetchStates();
                    this.fetchOperators();
                    this.fetchSources();
                    if (this.isVisibleColumn('subdivision')) {
                        this.fetchSubdivisions();
                    }
                }
            },
            fetchGrid() {
                this.loading = true;
                RequestService.getAll(this.requestGridsOptions.options, this.requestGridsOptions.filter).then(data => {
                    if (this.exclude) {
                        data.content = data.content.filter(el => this.exclude.indexOf(el.id) === -1);
                    }
                    this.requests = data.content;
                    this.totalElements = data.totalElements;
                    this.loading = false;
                });
            },
            fetchSubdivisions() {
                SubdivisionService.getAlls().then(data => {
                    this.subdivisions = data.content;
                });
            },
            fetchOperators() {
                UserService.getAllOperators().then(data => {
                    this.operators = data.content;
                });
            },
            fetchStates() {
                RequestService.getAllStates().then(data => {
                    this.states = data;
                });
            },
            fetchSources() {
                SourceService.getAll().then(data => {
                    this.sources = data;
                });
            },
            deleteSelected() {
                RequestService.deleteBulk(this.selected).then(() => {
                    this.fetchGrid();
                    this.deleteBulkDialog = false;
                    this.selected = [];
                });
            },
            deleteItem(id) {
                RequestService.delete(id).then(() => {
                    this.fetchGrid();
                });
            },
            calcTableHeight() {
                return window.innerHeight - 200;
            },
            onResize() {
                this.tableHeight = this.calcTableHeight();
            },
            isVisibleColumn(column) {
                return this.headers.find(el => el.value === column) !== undefined;
            },
            editRequest(id, prevItem, nextItem) {
                this.editedRequest = id.toString();
                this.prevRequest = !_.isUndefined(prevItem) ? prevItem.id : null;
                this.nextRequest = !_.isUndefined(nextItem) ? nextItem.id : null;
                this.dialog.requestEdit.modal = true;
            },
            closeEdit() {
                this.dialog.requestEdit.modal = false;
                this.editedRequest = null;
                this.editedRequestKey++;
            },
            changeEdited(id) {
                this.editedRequest = id;
                let requestIndex = this.requests.findIndex(el => {
                    return el.id == id;
                });

                let prevItem = this.requests[requestIndex - 1];
                let nextItem = this.requests[requestIndex + 1];

                this.prevRequest = !_.isUndefined(prevItem) ? prevItem.id : null;
                this.nextRequest = !_.isUndefined(nextItem) ? nextItem.id : null;

                this.editedRequestKey++;
            },
            closeEditAndFetch() {
                this.closeEdit();
                this.fetchGrid()
            },
            isHighlighted(item) {
                return item.isNew || _.includes(this.selected, item.id)
            }
        },
        computed: {
            itemPerPage: function() {
                return this.$helper.permission.canSeeMoreRequestsOnGrid(this.user) ? [20, 30, 50, 100] : [20, 30, 50];
            },
            headers: function() {
                return  this.$helper.requestGridColumns.getForRole(this.user.role);
            },
            ...mapState(['user'])
        },
        created() {
            this.$store.dispatch('loading', false);
            this.fetch();

            this.$store.subscribeAction((action) => {
                if (action.type === 'newRequestReceived' && !this.filter) {
                    let request = action.payload;
                    request.isNew = true;
                    this.requests.unshift(action.payload);
                }
            })
        },
        beforeCreate() {
            this.$store.dispatch('loading', true);
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },
        mounted() {
            window.addEventListener('resize', this.onResize)
        }
    };
</script>
<style lang="scss">
    header {
        display: none !important;
    }
    .v-content {
        padding-top: 0 !important;
    }
</style>
