<template>
    <div>
        <RequestGrid v-if="$helper.permission.canSeeRequest(user)" @forceUpdate="key++" :key="key"></RequestGrid>
        <AppointmentsGrid v-if="$helper.permission.canSeeAppointments(user)" @forceUpdate="key++" :key="key"></AppointmentsGrid>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import RequestGrid from "../requests/RequestGrid";
    import AppointmentsGrid from "../appointments/AppointmentsGrid";

    export default {
        name: 'HomePage',
        components: {AppointmentsGrid, RequestGrid},
        data() {
            return {
                key: 0
            }
        },
        computed: {
            ...mapState(['user'])
        },
    };
</script>
