<template>
    <div>
        <v-card-title>
            Список оплат
            <v-spacer></v-spacer>
            <div>
                <v-dialog v-model="dialog.paymentAdd.modal" max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn large color="green" v-on="on">
                            Добавить оплату
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">Добавить оплату</span>
                        </v-card-title>
                        <v-card-text>
                            <v-text-field v-model="form.paidAmount" label="Сумма"></v-text-field>
                            <v-alert v-if="form.errors.has('paidAmount')" dense outlined type="error" :icon="false">
                                {{form.errors.get('paidAmount') }}
                            </v-alert>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="dialog = false">Отмена</v-btn>
                            <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
        </v-card-title>
        <v-data-table class="elevation-1"
                      :headers="headers"
                      :items="payments"
                      :options.sync="options"
                      :server-items-length="totalElements"
                      item-key="id"
                      :loading="loading"
                      loading-text="Загрузка ..."
                      :footer-props="{'items-per-page-options':[20, 30, 50]}"
        >
            <template v-slot:body="{ items }">
                <tbody>
                <tr>
                    <td width="160">
                        <v-text-field v-model="filter.id" type="text"></v-text-field>
                    </td>
                    <td width="500">
                        <v-dialog ref="dialog"
                                  v-model="dialog.createdAt.modal"
                                  :return-value.sync="filter.createdAtDates"
                                  persistent width="290px">
                            <template v-slot:activator="{ on }">
                                <v-text-field
                                        v-model="filter.createdAtDates"
                                        prepend-icon="mdi-calendar-month-outline"
                                        readonly
                                        clearable
                                        v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker v-model="dialog.createdAt.model"
                                           type="date"
                                           scrollable
                                           range
                                           first-day-of-week="1">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="dialog.createdAt.modal = false">Отмена</v-btn>
                                <v-btn text color="primary" @click="$refs.dialog.save(dialog.createdAt.model)">
                                    Выбрать
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </td>
                    <td>
                        <v-text-field v-model="filter.paidAmount" type="text"></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="filter.requestPrice" type="text"></v-text-field>
                    </td>
                    <td>
                        <v-text-field v-model="filter.requestQuantity" type="text"></v-text-field>
                    </td>
                    <td></td>
                </tr>
                <tr v-for="item in items" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ $moment(item.createdAt).dateTimeFormat() }}</td>
                    <td>{{ item.paidAmount }}</td>
                    <td>{{ item.requestPrice }}</td>
                    <td>{{ item.requestQuantity }}</td>
                    <td>{{ item.remainingBalance }}</td>
                </tr>
                </tbody>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import PaymentService from "../_services/PaymentService";
    import Form from "../_helpers/_form/Form";

    export default {
        name: "PaymentGrid",
        data() {
            return {
                headers: [
                    {text: 'ID', value: 'id'},
                    {text: 'Дата', value: 'createdAt'},
                    {text: 'Сумма', value: 'paidAmount'},
                    {text: 'Стоимость заявки', value: 'requestPrice'},
                    {text: 'Кол-во заявок', value: 'requestQuantity'},
                    {text: 'Остаток на балансе', value: 'remainingBalance'}
                ],
                form: new Form({}),
                dialog: {
                    createdAt: {
                        model: [],
                        modal: false
                    },
                    paymentAdd: {
                        modal: false
                    }
                },
                payments: [],
                loading: true,
                options: {
                    sortBy: ['id'],
                    sortDesc: [true]
                },
                filter: {},
                totalElements: 0
            }
        },
        watch: {
            options: {
                handler() {
                    this.fetchGrid();
                },
                deep: true
            },
            filter: {
                handler() {
                    this.fetchGrid();
                },
                deep: true
            },
        },
        methods: {
            fetch() {
            },
            fetchGrid() {
                this.loading = true;
                PaymentService.getAll(this.options, this.filter).then(data => {
                    this.payments = data.content;
                    this.totalElements = data.totalElements;
                    this.loading = false;
                });
            },
            save() {
                PaymentService.create(this.form).then(() => {
                    this.dialog.paymentAdd.modal = false;
                    this.fetchGrid();
                });
            },
        },
        created() {
            this.fetch();
            this.$store.dispatch('loading', false);
        },
        beforeCreate() {
            this.$store.dispatch('loading', true);
        }
    };
</script>
