<template>
    <div>
        <v-row align="center">
            <v-col md="auto">
                <v-card-title>Напоминания</v-card-title>
            </v-col>
            <v-col md="auto">
                <v-btn fab text color="white darken-2" @click="prev">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn fab text color="white darken-2" @click="next">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
                <v-toolbar flat hidden>
                    <v-toolbar-title v-if="$refs.calendar">
                        {{ $refs.calendar.title }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
            </v-col>
            <v-col md="auto">
                <v-btn color="green" class="mx-4" @click="showDialog(null)">
                    <v-icon left>mdi-calendar-clock</v-icon>
                    <div>Добавить напоминание</div>
                </v-btn>
                <ReminderDialog :id.sync="editedItemId" :dialogVisible="dialogVisible" @close="dialogClose" @save="onRemindAdd" @delete="onRemindDelete"></ReminderDialog>
            </v-col>
            <v-col>
                <v-menu
                        bottom
                        right
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                outlined
                                color="blue"
                                v-bind="attrs"
                                v-on="on"
                        >
                            <span>{{ typeToLabel[type] }}</span>
                            <v-icon right>
                                mdi-menu-down
                            </v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="type = 'day'">
                            <v-list-item-title>День</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'week'">
                            <v-list-item-title>Неделя</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="type = 'month'">
                            <v-list-item-title>Месяц</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </v-col>
        </v-row>
        <v-card-text>
            <v-row>
                <v-col>
                    <v-calendar
                            v-model="focus"
                            ref="calendar"
                            first-time="08:00"
                            :value="today"
                            :events="events"
                            :type="type"
                            :event-color="getEventColor"
                            @change="fetchEvents"
                            @click:event="editEvent"
                    ></v-calendar>
                </v-col>
            </v-row>
        </v-card-text>
    </div>
</template>

<script>

    import ReminderDialog from "./ReminderDialog";
    import ReminderService from "../_services/ReminderService";
    import {mapState} from 'vuex';

    export default {
        name: "ReminderGrid",
        components: {ReminderDialog},
        data: () => ({
            focus: '',
            dialogVisible: false,
            today: new Date().toDateString(),
            editedItemId: null,
            colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
            events: {},
            type: 'month',
            typeToLabel: {
                month: 'Месяц',
                week: 'Неделя',
                day: 'День'
            },
        }),
        mounted () {
            this.$refs.calendar.checkChange()
        },
        methods: {
            fetchEvents ({ start, end }) {
                ReminderService.getAll({}, {dates: [start.date, end.date].join(",")}).then(data => {
                    data.forEach((event) => {
                        event.color = 'deep-purple'
                        if (event.user.id != this.user.id) {
                            event.color = 'green'
                            event.name += ` (${event.user.fullName})`
                        }
                    })
                    this.events = data;
                });
            },
            showDialog (editItemId) {
                this.editedItemId = editItemId
                this.dialogVisible = true
            },
            getEventColor (event) {
                return event.color
            },
            prev () {
                this.$refs.calendar.prev()
            },
            next () {
                this.$refs.calendar.next()
            },
            setToday() {
                this.focus = ''
            },
            onRemindAdd() {
                this.$emit('forceUpdate')
            },
            onRemindDelete() {
                this.$emit('forceUpdate')
            },
            editEvent(event) {
                this.showDialog(event.event.id)
            },
            dialogClose () {
                this.dialogVisible = false
            },
            rnd (a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a
            }
        },
        computed: {
            ...mapState(['user'])
        },
    }
</script>

<style>
    .v-calendar {
        max-width: 1200px !important;
        background: none !important;
        border: 1px solid #444 !important;
    }
    .v-calendar-daily__scroll-area {
        overflow-y: visible !important;
    }
    .theme--dark.v-calendar-events .v-event-timed {
        border: none !important;
    }
</style>
