<template>
    <div v-if="!loading">
        <v-list subheader>
            <v-subheader>Биллинг</v-subheader>
            <div v-for="account in accounts" :key="account.id">
                <Balance :account="account" :key="balanceKey"></Balance>
            </div>
        </v-list>
    </div>
</template>

<script>
    import AccountService from "../_services/AccountService";
    import {mapState} from 'vuex';
    import Balance from "./billing/Balance";

    export default {
        name: "Billing",
        components: {Balance},
        data() {
            return {
                accounts: [],
                balanceKey: 1,
                loading: true
            }
        },
        methods: {
            fetch() {
                this.loading = true;
                this.loadAccounts();
            },
            loadAccounts() {
                this.loadMyAccount();
                this.loadAllAccounts();
            },
            loadMyAccount() {
                if (this.user.role === 'PROVIDER') {
                    AccountService.getMyAccount().then(data => {
                        this.accounts = [data];
                        this.loading = false;
                    });
                }
            },
            loadAllAccounts() {
                if (this.user.role === 'ADMIN') {
                    AccountService.getAllAccounts().then(data => {
                        this.accounts = data;
                        this.loading = false;
                    });
                }
            }
        },
        computed: {
            ...mapState(['user'])
        },
        created() {
            this.fetch();
            this.$store.dispatch('loading', false);
            this.$store.subscribeAction((action) => {
                if (action.type === 'updateAccountReceived') {
                    const receivedAccount = action.payload;
                    const existAccountIndex = this.accounts.findIndex(account => {
                        return account.id === receivedAccount.id
                    });
                    if (existAccountIndex !== -1) {
                        this.accounts[existAccountIndex] = action.payload;
                    } else {
                        this.accounts.push(action.payload);
                    }
                    this.balanceKey++;
                }
            });
        },
        beforeCreate() {
            this.$store.dispatch('loading', true);
        }
    }
</script>

<style scoped>

</style>
