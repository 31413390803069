<template>
    <div>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                        type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-card v-if="!loading">
            <v-card-text>
                <form>
                    <v-row>
                        <v-col cols="12" xl="8">
                            <v-row>
                                <v-col>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="form.firstName" label="Имя"></v-text-field>
                                            <v-alert v-if="form.errors.has('firstName')" dense outlined type="error"
                                                     :icon="false">
                                                {{form.errors.get('firstName') }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="form.lastName" label="Фамилия"></v-text-field>
                                            <v-alert v-if="form.errors.has('lastName')" dense outlined type="error"
                                                     :icon="false">
                                                {{form.errors.get('lastName') }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field v-model="form.email" label="Email"
                                                          :disabled="user != null"></v-text-field>
                                            <v-alert v-if="form.errors.has('email')" dense outlined type="error"
                                                     :icon="false">
                                                {{form.errors.get('email') }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col v-for="(innerPhoneNumber, index) in form.innerPhoneNumbers" :key="index">
                                            <v-text-field v-model="innerPhoneNumber.number" label="Внутренний номер"></v-text-field>
                                            <v-alert v-if="form.errors.has('innerPhoneNumbers['+ index +'].number')" dense outlined type="error" :icon="false">
                                                {{form.errors.get('innerPhoneNumbers['+ index +'].number')}}
                                            </v-alert>
                                        </v-col>
                                        <v-col>
                                            <v-row>
                                                <v-col sm="auto">
                                                    <v-btn small outlined color="green" @click="addInnerPhoneNumber">
                                                        <span v-if="form.innerPhoneNumbers.length < 1">добавить внутренний номер</span>
                                                        <span v-if="form.innerPhoneNumbers.length > 0">добавить</span>
                                                    </v-btn>
                                                </v-col>
                                                <v-col sm="auto" v-if="form.innerPhoneNumbers.length > 0">
                                                    <v-btn small outlined color="red" @click="deleteInnerPhoneNumber">
                                                        удалить
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-alert dense outlined type="error" :icon="false">
                                        * Телефон должен быть уникальным для каждого пользователя
                                    </v-alert>
                                    <v-row>
                                        <v-col>
                                            <v-select prepend-icon="mdi-account-lock-outline"
                                                      v-model="form.role"
                                                      label="Роль" :items="roles"></v-select>
                                            <v-alert v-if="form.errors.has('role')" dense outlined type="error"
                                                     :icon="false">
                                                {{form.errors.get('role') }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-select prepend-icon="mdi-account-group"
                                                      v-model="form.subdivision"
                                                      label="Орг. отдел" :items="subdivisions" item-text="name" item-value="id" clearable></v-select>
                                            <v-alert v-if="form.errors.has('subdivision')" dense outlined type="error"
                                                     :icon="false">
                                                {{form.errors.get('subdivision') }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-select prepend-icon="mdi-office-building"
                                                      v-model="form.office"
                                                      label="Офис" :items="offices" item-text="name" item-value="id" clearable></v-select>
                                            <v-alert v-if="form.errors.has('office')" dense outlined type="error"
                                                     :icon="false">
                                                {{form.errors.get('office') }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-text-field clearable append-icon="mdi-dice-multiple-outline"
                                                          prepend-icon="mdi-account-key-outline"
                                                          v-model="form.password"
                                                          label="Новый пароль"
                                                          @click:append="generatePass"></v-text-field>
                                            <v-alert v-if="form.errors.has('password')" dense outlined type="error"
                                                     :icon="false">
                                                {{form.errors.get('password') }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="$helper.env.isTrue('VUE_APP_CAN_PUBLISH_ON_SITE')">
                                        <v-col>
                                            <v-text-field v-model="form.portalLawyer" label="Id юриста на портале"></v-text-field>
                                            <v-alert v-if="form.errors.has('portalLawyer')" dense outlined type="error" :icon="false">
                                                {{form.errors.get('portalLawyer') }}
                                            </v-alert>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-alert v-if="form.errors.any()" dense outlined type="error" :icon="false">
                                Исправьте ошибки
                            </v-alert>
                            <v-alert v-if="form.errors.has('error')" dense outlined type="error" :icon="false">
                                {{form.errors.get('error')}}
                            </v-alert>
                            <div v-show="!form.busy">
                                <v-btn large color="green" @click="user ? update(false) : save(false)">
                                    Сохранить
                                </v-btn>
                            </div>
                            <v-progress-circular v-show="form.busy" indeterminate color="primary"></v-progress-circular>
                        </v-col>
                    </v-row>
                </form>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>
    import {Form} from '../_helpers/_form';
    import UserService from "../_services/UserService";
    import SubdivisionService from "../_services/SubdivisionService";
    import OfficeService from "../_services/OfficeService";
    import {generate as passwordGenerator} from "generate-password";

    export default {
        name: "UserForm",
        props: {
            user: {
                type: Object,
                default: null
            }
        },
        data() {
            return {
                form: this.user
                    ? new Form({...this.user, password: null})
                    : new Form({
                        firstName: '',
                        lastName: '',
                        email: '',
                        role: null,
                        innerPhoneNumbers: [],
                        password: '',
                        subdivision: null,
                        office: null
                    }),
                roles: [],
                subdivisions: [],
                offices: [],
                loading: true
            }
        },
        methods: {
            fetch() {
                this.fetchRoles();
                this.fetchSubdivisions();
                this.fetchOffices();
                this.loading = false;
            },
            fetchRoles() {
                UserService.getRoles().then(data => {
                    this.roles = data;
                });
            },
            fetchSubdivisions() {
                SubdivisionService.getAlls().then(data => {
                    this.subdivisions = data.content;
                });
            },
            fetchOffices() {
                OfficeService.getAll().then(data => {
                    this.offices = data;
                });
            },
            save() {
                UserService.create(this.form).then(() => {
                    this.callback()
                });
            },
            update() {
                UserService.update(this.user.id, this.form).then(() => {
                    this.callback()
                });
            },
            callback() {
                this.$router.push('/users');
            },
            generatePass() {
                this.form.password = passwordGenerator({
                    length: 10,
                    numbers: true
                });
            },
            addInnerPhoneNumber() {
                this.form.innerPhoneNumbers.push({number: ''})
            },
            deleteInnerPhoneNumber() {
                this.form.innerPhoneNumbers.pop()
            }
        },
        created() {
            this.fetch();
        },
    }
</script>

<style lang="scss">
</style>
