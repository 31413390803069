import {http} from "../_helpers";
import Service from "./Service";

class ReportService extends Service {

    getReport(filter) {
        return http.get(this.makeOptionsUrl(`/reports`, {}, filter));
    }
}

export default new ReportService;
