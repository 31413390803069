<template>
    <v-dialog v-model="intDialogVisible" max-width="500px">
        <v-card>
            <v-card-title>
                <span v-if="!id" class="headline">Добавить напоминание</span>
                <span v-if="id" class="headline">Редактировать напоминание</span>
            </v-card-title>
            <div v-if="loading" class="pa-5">
                <v-skeleton-loader type="paragraph, button"></v-skeleton-loader>
            </div>
            <div v-if="!loading" class="px-3">
                <v-card-text>
                    <input v-if="form.requestId" type="hidden" v-model="form.requestId" />
                    <v-row v-if="form.requestId">
                        <v-col>
                            <router-link :to="`/requests/${form.requestId}/edit`">
                                Заявка #{{form.requestId}}
                            </router-link>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="form.name" label="Заголовок"></v-text-field>
                            <v-alert v-if="form.errors.has('name')" dense outlined type="error"
                                     :icon="false">{{ form.errors.get('name') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-dialog ref="dateDialog" v-model="dateDialog"
                                      :return-value.sync="form.date"
                                      persistent width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="form.date"
                                            label="Дата"
                                            prepend-icon="mdi-calendar-month-outline"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="form.date" type="date" scrollable
                                               first-day-of-week="1"
                                               :min="new Date().toISOString().slice(0,10)">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="dateDialog = false">Отмена</v-btn>
                                    <v-btn text color="primary"
                                           @click="$refs.dateDialog.save(form.date)">
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-dialog>
                            <v-alert v-if="form.errors.has('date')" dense outlined type="error"
                                     :icon="false">{{ form.errors.get('date') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-dialog ref="timeDialog" v-model="timeDialog"
                                      :return-value.sync="form.time"
                                      persistent width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                            v-model="form.time"
                                            label="Время"
                                            prepend-icon="mdi-alarm-check"
                                            readonly
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-time-picker v-model="form.time"
                                               format="24hr"
                                               scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="timeDialog = false">Отмена</v-btn>
                                    <v-btn text color="primary"
                                           @click="$refs.timeDialog.save(form.time)">
                                        OK
                                    </v-btn>
                                </v-time-picker>
                            </v-dialog>
                            <v-alert v-if="form.errors.has('time')" dense outlined type="error"
                                     :icon="false">
                                {{form.errors.get('time') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="form.description" label="Описание"></v-text-field>
                            <v-alert v-if="form.errors.has('description')" dense outlined type="error"
                                     :icon="false">{{ form.errors.get('description') }}
                            </v-alert>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="id ? update() : save()">Сохранить</v-btn>
                    <v-btn color="blue darken-1" text @click="intDialogVisible = false">Отмена</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn v-if="id" color="red darken-1" text @click="deleteReminder()">Удалить</v-btn>
                </v-card-actions>
            </div>
        </v-card>
    </v-dialog>
</template>
<script>
    import Form from "../_helpers/_form/Form";
    import ReminderService from "../_services/ReminderService";

    export default {
        name: "ReminderDialog",
        props: {
            id: Number,
            requestId: String,
            dialogVisible: Boolean
        },
        data() {
            return {
                form: new Form({}),
                timeDialog: false,
                dateDialog: false,
                loading: true
            }
        },
        watch: {
            id(newValue) {
                this.fetchItem(newValue);
            }
        },
        methods: {
            save() {
                ReminderService.create(this.form).then(() => {
                    this.$emit('save')
                });
            },
            update() {
                ReminderService.update(this.id, this.form).then(() => {
                    this.$emit('save')
                });
            },
            fetchItem() {
                this.loading = true;
                if (this.id) {
                    ReminderService.getOne(this.id).then(data => {
                        this.form = new Form(data)
                        this.loading = false;
                    });
                } else {
                    this.form = new Form({})
                    if (this.requestId) {
                        this.form.requestId = this.requestId
                        this.form.name = `Перезвон #${this.form.requestId}`
                    }
                    this.loading = false;
                }
            },
            deleteReminder() {
                this.loading = true;
                ReminderService.delete(this.id).then(() => {
                    this.loading = false;
                    this.$emit('delete')
                });
            }
        },
        computed: {
            date() {
                return this.form.date;
            },
            intDialogVisible: {
                get: function () {
                    if (this.dialogVisible) {
                        // Some dialog initialization code could be placed here
                        // because it is called only when this.dialogVisible changes
                    }
                    return this.dialogVisible
                },
                set: function (value) {
                    if (!value) {
                        this.$emit('close')
                    }
                }
            }
        },
        mounted() {
            this.fetchItem()
        }
    }
</script>

<style scoped>

</style>
