export default class Service {

    makeOptionsUrl(url, options, filter) {
        const {sortBy, sortDesc, page, itemsPerPage} = options;

        let urlPart = [];
        if (sortBy) {
            sortBy.forEach(function (value, index) {
                let sortPart = value;
                if (sortDesc[index]) {
                    sortPart += `,desc`;
                }
                urlPart.push({'sort': sortPart});
            });
        }
        if (page) {
            urlPart.push({'page': page - 1}); //spring data first page index is 0
        }
        if (itemsPerPage) {
            urlPart.push({'size': itemsPerPage});
        }

        Object.keys(filter).forEach(key => {
            let value = filter[key];
            if (value) {
                if (typeof value === 'object' && !Array.isArray(value)) {
                    Object.keys(value).forEach(childKey => {
                        let childValue = value[childKey];
                        if (childValue) {
                            urlPart.push({[key + "." + childKey]: childValue});
                        }
                    });
                } else {
                    urlPart.push({[key]: value});
                }
            }
        });

        return url + '?' + this.urlFromArray(urlPart);
    }

    urlFromArray(params) {
        let parameters = [];
        params.forEach(function (value) {
            parameters.push(encodeURI(Object.keys(value)[0] + '=' + Object.values(value)[0]));
        });
        return parameters.join('&');
    }
}
