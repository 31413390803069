import {http} from "../_helpers";
import Service from "./Service";

class PaymentService extends Service {

    getAll(options, filter) {
        return http.get(this.makeOptionsUrl(`/payments`, options, filter));
    }

    create(form) {
        return http.post(`/payments`, form);
    }
}

export default new PaymentService;
