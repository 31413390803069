<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="green mr-3" v-if="$helper.env.isTrue('VUE_APP_CAN_PUBLISH_ON_SITE')">
                <span v-if="request.isPublishedOnSite">Обновить на сайте</span>
                <span v-if="!request.isPublishedOnSite">Опубликовать на сайте</span>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">Опубликовать вопрос на сайте</span>
            </v-card-title>
            <v-card-text>
                <div>
                    <v-text-field v-model="form.title" label="Заголовок"></v-text-field>
                    <v-alert v-if="form.errors.has('title')" dense outlined type="error" :icon="false">
                        {{form.errors.get('title') }}
                    </v-alert>
                </div>
                <div>
                    <v-textarea v-model="form.text" label="Текст"></v-textarea>
                    <v-alert v-if="form.errors.has('text')" dense outlined type="error" :icon="false">
                        {{form.errors.get('text') }}
                    </v-alert>
                </div>
                <div>
                    <v-select v-model="form.area" :items="areas" item-value="id"
                              item-text="name"
                              label="Область права"></v-select>
                    <v-alert v-if="form.errors.has('area')" dense outlined type="error"
                             :icon="false">
                        {{form.errors.get('area')}}
                    </v-alert>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <div v-show="!form.busy">
                    <v-btn color="blue darken-1" text @click="dialog = false">Отмена</v-btn>
                    <v-btn color="blue darken-1" text @click="save">Сохранить</v-btn>
                </div>
                <v-progress-circular v-show="form.busy" indeterminate color="primary"></v-progress-circular>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import Form from "../../_helpers/_form/Form";
    import RequestService from "../../_services/RequestService";

    export default {
        name: "QuestionPublishDialog",
        props: {
            id: Number,
            request: Object,
            areas: Array
        },
        data() {
            this.request.question = this.request.question || {};
            this.request.question.text = this.request.question.text || this.request.problem;
            this.request.question.area = this.request.area;
            return {
                form: new Form(this.request.question),
                dialog: false
            }
        },
        methods: {
            save() {
                RequestService.publishedOnSite(this.id, this.form).then(() => {
                    this.request.isPublishedOnSite = true;
                    this.dialog = false;
                    this.$emit('saved');
                });
            }
        }
    }
</script>

<style scoped>

</style>
