import Vue from 'vue';
import VueECharts from 'vue-echarts';
import App from './app/App';
import vuetify from './_plugins/vuetify';
import {helper, router} from './_helpers';
import store from './_store/';
import moment from "moment";
import _ from "lodash";
import {config} from "./_helpers/constants";

import DefaultLayout from './_layouts/DefaultLayout.vue'
import AuthLayout from './_layouts/AuthLayout.vue'
import VueMask from 'v-mask';
import VueFlashMessage from 'vue-flash-message';

Vue.component('v-chart', VueECharts)
Vue.component('default-layout', DefaultLayout);
Vue.component('auth-layout', AuthLayout);

import('./_helpers/prototypes');

Vue.use(VueMask);

Vue.prototype.$moment = moment;
Vue.prototype.$config = config;
Vue.prototype.$helper = helper;
Vue.prototype.$_ = _;

global.store = store;

Vue.use(VueFlashMessage);

new Vue({
    el: '#app',
    store,
    router,
    vuetify,
    render: h => h(App),
    created() {
        this.$store.dispatch('checkPageTitle', this.$route.meta.title).then();
        this.$store.dispatch('checkAuth').then();
    }
});
