<template>
    <div>
        <v-card-title>
            Отчеты
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col v-if="$helper.permission.canAssignRequest(user)">
                    <v-label>Тип отчета</v-label>
                    <v-select v-model="reportGridsOptions.filter.reportType" item-value="id" item-text="text" :items="reportTypes"></v-select>
                </v-col>
                <v-col>
                    <v-label>Дата</v-label>
                    <v-dialog ref="dialog"
                              v-model="dialog.createdAt.modal"
                              :return-value.sync="reportGridsOptions.filter.request.createdAtDates"
                              persistent width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                    v-model="reportGridsOptions.filter.request.createdAtDates"
                                    prepend-icon="mdi-calendar-month-outline"
                                    readonly
                                    clearable
                                    v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker v-model="dialog.createdAt.model"
                                       type="date"
                                       scrollable
                                       range
                                       first-day-of-week="1">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="dialog.createdAt.modal = false">Отмена</v-btn>
                            <v-btn text color="primary" @click="$refs.dialog.save(dialog.createdAt.model)">
                                Выбрать
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                <v-col v-if="$helper.permission.canAssignRequest(user)">
                    <v-label>Источник</v-label>
                    <v-select v-model="reportGridsOptions.filter.request.source" item-value="id" item-text="name" :items="sources" clearable></v-select>
                </v-col>
                <v-col v-if="$helper.permission.canAssignRequest(user)">
                    <v-label>Оператор</v-label>
                    <v-select v-model="reportGridsOptions.filter.request.operator" :items="operators" clearable
                              item-value="id"
                              item-text="fullName"></v-select>
                </v-col>
                <v-col>
                    <v-btn large color="green" @click="getReport">
                        Сформировать
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-text>

        <v-data-table
                :headers="headers"
                :items="reportsTuples"
                hide-default-footer
                disable-pagination
                :custom-sort="customSort"
        >
            <template v-slot:body="{ items }">
                <tbody>
                <tr v-for="item in items" :key="item.id">
                    <td>{{item.item}}</td>
                    <td>{{item.count}}</td>
                    <td v-html="printStateRow(item, 'ZAPIS')"></td>
                    <td v-html="printStateRow(item, 'APPOINTMENT_ZAPIS')"></td>
                    <td v-html="printStateRow(item, 'CAME')"></td>
                    <td v-html="printStateRow(item, 'APPOINTMENT_CAME')"></td>
                    <td v-html="printStateRow(item, 'FAILURE')"></td>
                    <td v-html="printStateRow(item, 'SHLAK')"></td>
                    <td v-html="printStateRow(item, 'BRAK')"></td>
                    <td v-html="printStateRow(item, 'CANCEL_ZAPIS')" width="80"></td>
                    <td v-html="printStateRow(item, 'RECALL')"></td>
                    <td v-html="printStateRow(item, 'PULL_OFF')"></td>
                    <td v-html="printStateRow(item, 'CONTROL')"></td>
                    <td v-html="getItemKPD(item)"></td>
                    <td v-html="printStateRow(item, ['ZAPIS', 'CAME'], true)"></td>
                </tr>
                <tr v-if="summary.length > 0">
                    <td><b>Всего:</b></td>
                    <td v-html="printSummaryRow(null, true)"></td>
                    <td v-html="printSummaryRow('ZAPIS')"></td>
                    <td v-html="printSummaryRow('APPOINTMENT_ZAPIS')"></td>
                    <td v-html="printSummaryRow('CAME')"></td>
                    <td v-html="printSummaryRow('APPOINTMENT_CAME')"></td>
                    <td v-html="printSummaryRow('FAILURE')"></td>
                    <td v-html="printSummaryRow('SHLAK')"></td>
                    <td v-html="printSummaryRow('BRAK')"></td>
                    <td v-html="printSummaryRow('CANCEL_ZAPIS')"></td>
                    <td v-html="printSummaryRow('RECALL')"></td>
                    <td v-html="printSummaryRow('PULL_OFF')"></td>
                    <td v-html="printSummaryRow('CONTROL')"></td>
                    <td v-html="getItemKPD()"></td>
                    <td v-html="printSummaryRow(['ZAPIS', 'CAME'])"></td>
                </tr>
                </tbody>
            </template>
        </v-data-table>
        <Charts v-if="states.length > 0 && reportsTuples.length > 0" :states="states" :reportsTuples="reportsTuples" :key="chartsKey"></Charts>
    </div>
</template>

<script>
    import RequestService from "../_services/RequestService";
    import {mapState} from 'vuex';
    import SourceService from "../_services/SourceService";
    import Charts from "./Charts";
    import UserService from "../_services/UserService";
    import ReportService from "../_services/ReportService";

    export default {
        name: 'ReportGrid',
        components: {Charts},
        data: function() {
            return {
                reportsTuples: [],
                summary: [],
                allCount: 0,
                states: [],
                sources: [],
                operators: [],
                loading: false,
                headers: [
                    {text: '', value: 'item'},
                    {text: 'Заявки', value: 'count'},
                    {text: 'Запись (общее)', value: 'ZAPIS'},
                    {text: 'Запись (на сегодня)', value: 'APPOINTMENT_ZAPIS'},
                    {text: 'Пришел', value: 'CAME'},
                    {text: 'Пришел (итого)', value: 'APPOINTMENT_CAME'},
                    {text: 'Отказ', value: 'FAILURE'},
                    {text: 'Шлак', value: 'SHLAK'},
                    {text: 'Брак', value: 'BRAK'},
                    {text: 'Отмена консультации', value: 'CANCEL_ZAPIS'},
                    {text: 'Перезвон', value: 'RECALL'},
                    {text: 'Снята', value: 'PULL_OFF'},
                    {text: 'Контроль', value: 'CONTROL'},
                    {text: 'КПД', value: 'KPD'},
                    {text: 'Доход', value: 'CAME'}
                ],
                dialog: {
                    createdAt: {
                        model: [],
                        modal: false
                    }
                },
                reportTypes: [
                    {
                        "id": "OPERATOR",
                        "text": "Оператор"
                    },
                    {
                        "id": "SOURCE",
                        "text": "Источник"
                    },
                    {
                        "id": "SOURCE",
                        "text": "Источник"
                    },
                    {
                        "id": "SUBDIVISION",
                        "text": "Подразделение"
                    },
                    {
                        "id": "OFFICE",
                        "text": "Офис"
                    }
                ],
                chartsKey: 0
            }
        },
        watch: {
            reportsTuples: function() {
                this.chartsKey++;
            }
        },
        methods: {
            customSort: function(items, index, isDesc) {
                items.sort((a, b) => {
                    if (!index[0]) return;
                    let aRes = a[index[0]];
                    let bRes = b[index[0]];
                    if (index[0] !== 'count') {
                        let aPercent = a.tuples.find(el => el.state === index[0]);
                        let bPercent = b.tuples.find(el => el.state === index[0]);

                        aRes = aPercent ? aPercent.count / a.count * 100 :  0;
                        bRes = bPercent ? bPercent.count / b.count * 100 :  0;
                    }

                    if (!isDesc[0]) {
                        return bRes - aRes;
                    } else {
                        return aRes - bRes;
                    }
                });
                return items;
            },
            printSummaryRow(state, withoutPercent = false) {
                let counts = 0, procents = 0;
                if (Array.isArray(state)) {
                    let _this = this;
                    state.forEach(function(el) {
                        let {count} = _this.getSummaryRow(el);
                        counts += count;
                    });
                    procents = counts / this.getItemKPD() * 100;
                } else {
                    let {count, procent} = this.getSummaryRow(state);
                    counts = count;
                    procents = procent;
                }
                if (procents > 0 && !withoutPercent) {
                    return `${counts} (<span class="yellow_link">${Math.round((procents + Number.EPSILON) * 100) / 100}</span>%)`;
                } else {
                    return `${counts}`;
                }
            },
            getSummaryRow(state) {
                let item = this.summary.find(el => el.state === state);
                let count = 0, procent = 0;
                if (item) {
                    count = item.count;
                    if (this.allCount > 0)
                        procent = count / this.getItemKPD() * 100;
                }
                return {...{count, procent}};
            },
            printStateRow(item, state, onlyPercent = false) {
                let tuples = 0, procents = 0;
                if (Array.isArray(state)) {
                    let _this = this;
                    state.forEach(function(el) {
                        let {tuple} = _this.getStateRow(item, el);
                        tuples += tuple;
                    });
                    procents = tuples / this.getItemKPD(item) * 100;
                } else {
                    let {tuple, procent} = this.getStateRow(item, state);
                    tuples = tuple;
                    procents = procent;
                }
                if (onlyPercent) {
                    return `${Math.round((procents + Number.EPSILON) * 100) / 100}%`;
                } else {
                    return `${tuples} (<span class="yellow_link">${Math.round((procents + Number.EPSILON) * 100) / 100}</span>%)`;
                }
            },
            getStateRow(item, state) {
                let tuple = item.tuples.find(el => el.state === state);
                let procent = 0;
                if (tuple) {
                    procent = tuple.count / item.count * 100;
                    tuple = tuple.count;
                } else {
                    tuple = 0;
                }
                return {...{tuple, procent}};
            },
            getItemKPD(item) {
                let tuple, allCount;
                if (item) {
                    tuple =  item.tuples.find(el => el.state === 'PULL_OFF');
                    allCount = item.count;
                } else {
                    tuple = this.summary.find(el => el.state === 'PULL_OFF');
                    allCount = this.allCount;
                }
                let  pullOffCount = tuple ? tuple.count : 0;
                return allCount - pullOffCount;
            },
            fetch() {
                this.fetchStates();
                this.fetchOperators();
                this.fetchSources();
            },
            getReport() {
                this.loading = true;
                if (this.$helper.isUserRole(this.user, "PROVIDER")) {
                    this.reportGridsOptions.filter.reportType = "SOURCE"
                }
                ReportService.getReport(this.reportGridsOptions.filter).then(data => {
                    this.reportsTuples = data.items;
                    this.summary = data.summary;
                    this.allCount = this.summary.find(el => el.state === null).count;
                    this.loading = false;
                });
            },
            fetchOperators() {
                UserService.getAllOperators().then(data => {
                    this.operators = data.content;
                });
            },
            fetchStates() {
                RequestService.getAllStates().then(data => {
                    this.states = data;
                });
            },
            fetchSources() {
                SourceService.getAll().then(data => {
                    this.sources = data;
                });
            }
        },
        computed: {
            ...mapState(['user', 'reportGridsOptions'])
        },
        created() {
            this.$store.dispatch('loading', false);
            this.fetch();
        },
        beforeCreate() {
            this.$store.dispatch('loading', true);
        }
    };
</script>
<style lang="scss">
    .yellow_link {
        color: #ffeb3b !important;
    }
</style>
