<template>
    <div>
        <v-card-title>
            Редактирование заявки
            <div class="ml-5">
                <v-btn small color="primary" class="mx-4" outlined :to="`/requests/${id}`">Просмотр заявки</v-btn>
            </div>
            <div class="ml-3">
                <v-dialog v-model="deleteDialog[id]" max-width="320"
                          v-if="request !== null && $helper.permission.canDeleteRequest(request, user)">
                    <template v-slot:activator="{ on }">
                        <v-btn small color="red" outlined v-on="on">
                            <v-icon class="mr-1" small color="red">mdi-delete</v-icon> Удалить
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-text class="text-center pt-7">Удалить выбранный элемент?</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text @click="deleteDialog[id] = false">Отмена</v-btn>
                            <v-btn color="red" text @click="deleteItem(id)">Удалить</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </div>
            <div class="ml-3">
                <v-btn small outlined color="green" class="mx-4" @click="reminderDialogVisible = true">
                    <v-icon left small>mdi-calendar-clock</v-icon>
                    <div>Добавить напоминание</div>
                </v-btn>
                <ReminderDialog :requestId="id" :dialogVisible="reminderDialogVisible" @close="reminderDialogVisible = false" @save="onRemindAdd"></ReminderDialog>
            </div>
            <div class="ml-3">
                <v-btn v-if="$helper.permission.canMakeCallback(user)" v-show="!callbackLoading" small outlined color="green" @click="makeCallback">
                    <v-icon left small>mdi-phone</v-icon>
                    <div>Позвонить</div>
                </v-btn>
                <v-progress-circular v-show="callbackLoading" :size="20" :width="2" indeterminate color="primary"></v-progress-circular>
            </div>
            <div class="ml-3">
                <v-btn v-if="prevRequest" fab text color="white darken-2" @click="changeEdited(prevRequest.toString())">
                    <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn v-if="nextRequest" fab text color="white darken-2" @click="changeEdited(nextRequest.toString())">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </div>
            <div class="text-right mx-3">
                <v-btn text color="error" @click="$emit('closeEdit')" icon>
                    <v-icon large>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
        </v-card-title>
        <v-row v-if="loading">
            <v-col cols="12" md="7">
                <v-skeleton-loader
                        type="heading, table-row@3, button"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <div v-if="!loading">
            <RequestForm v-if="request !== null" :page="page" @recallSelected="onRecallSelected" @closeAndUpdate="$emit('closeAndUpdate')"></RequestForm>
        </div>
    </div>
</template>

<script>
    import RequestForm from "./RequestForm";
    import ReminderDialog from "./../reminders/ReminderDialog";
    import {mapState} from 'vuex';
    import RequestService from "../_services/RequestService";
    import CallService from "../_services/CallService";

    export default {
        components: {RequestForm, ReminderDialog},
        props: {
            id: String,
            nextRequest: Number,
            prevRequest: Number
        },
        data() {
            return {
                request: null,
                page: null,
                deleteDialog: {},
                reminderDialogVisible: false,
                callbackLoading: false,
                loading: true
            }
        },
        methods: {
            fetch() {
                this.fetchRequest();
            },
            fetchRequest() {
                RequestService.getForm(this.id).then(data => {
                    this.page = data;
                    this.request = data.request;
                    this.loading = false;
                }, error => {
                    this.flashError(this.$helper.showError(error));
                });
            },
            deleteItem(id) {
                RequestService.delete(id).then(() => {
                    this.$emit('closeAndUpdate')
                });
            },
            onRemindAdd() {
                this.reminderDialogVisible = false;
                this.flashSuccess(`Напоминание добавлено`);
            },
            onRecallSelected() {
                this.reminderDialogVisible = true
            },
            changeEdited(id) {
                this.$emit("changeEdited", id);
            },
            makeCallback() {
                this.callbackLoading = true;
                CallService.callback(this.id).then(data => {
                    this.flashSuccess(`${data.resultDescription}`);
                    this.callbackLoading = false;
                });
            }
        },
        computed: {
            ...mapState(['user'])
        },
        created() {
            this.fetch();
        }
    };
</script>
