<template>
    <v-content class="fill-height">
        <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
                <v-col cols="12" sm="12" class="text-center">
                    <img src="../_assets/logo-white.svg" width="220">
                </v-col>
                <v-col cols="12" md="4" sm="8">
                    <v-card class="elevation-12">
                        <v-card-text class="pa-6">
                            <v-form>
                                <v-text-field label="Email" name="username" prepend-icon="mdi-account"
                                              type="text" v-model="username"/>
                                <v-text-field label="Пароль" name="password" prepend-icon="mdi-lock"
                                              type="password" v-model="password"/>
                            </v-form>
                            <v-alert dense outlined type="error" v-show="error">{{error}}</v-alert>
                        </v-card-text>
                        <v-card-actions class="pa-6 pt-0">
                            <v-spacer/>
                            <v-progress-circular color="primary" indeterminate
                                                 v-show="loading"></v-progress-circular>
                            <v-btn @click="handleSubmit" color="primary" large v-show="!loading">Войти</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-content>
</template>

<script>
    import {router} from '../_helpers';
    import UserService from '../_services/UserService';

    export default {
        data() {
            return {
                username: '',
                password: '',
                loading: false,
                returnUrl: '',
                error: ''
            }
        },
        created() {
            this.returnUrl = this.$route.query.returnUrl || '/';
            //this.$vuetify.theme.dark = false;
        },
        methods: {
            handleSubmit() {
                const {username, password} = this;

                this.error = '';
                this.loading = true;
                UserService.login(username, password)
                    .then(
                        () => router.push(this.returnUrl),
                        error => {
                            this.error = error;
                            this.loading = false;
                        }
                    );
            }
        }
    };
</script>
<style lang="scss" scoped>
    .v-content {
        background: #121212 !important;
    }
    h1 {
        color: #fff;
    }
    @media (min-width: 1904px) {
        .container {
            max-width: 1450px !important;
        }
    }
</style>
