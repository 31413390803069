<template>
    <div style="padding: 0 4px">
        <v-navigation-drawer
                v-model="primaryDrawer.model"
                :clipped="primaryDrawer.clipped"
                :floating="primaryDrawer.floating"
                :mini-variant="primaryDrawer.mini"
                :permanent="primaryDrawer.type === 'permanent'"
                :temporary="primaryDrawer.type === 'temporary'"
                app
                overflow
        >
            <div class="pa-2 pl-4 pt-4">
                <img src="../_assets/logo-white.svg" width="140" alt="ЛенЮр">
            </div>
            <v-divider></v-divider>
            <v-list-item three-line>
                <v-list-item-avatar v-if="false">
                    <img src="https://randomuser.me/api/portraits/women/81.jpg" alt="Аватар">
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-list-item-title>{{user.fullName}}</v-list-item-title>
                    <v-list-item-subtitle>{{user.roleDesc}}</v-list-item-subtitle>
                    <div class="pt-2">
                        <v-btn color="error" outlined x-small @click="logout">Выйти</v-btn>
                    </div>
                </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list dense>
                <v-list-item
                        v-for="menu in userMenu"
                        :key="menu.title"
                        link
                        :to="menu.link"
                >
                    <v-list-item-icon>
                        <v-icon>{{ menu.icon }}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ menu.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <Billing v-if="$helper.permission.canSeeTheBilling(user)"></Billing>
                <Settings></Settings>
            </template>
        </v-navigation-drawer>
        <v-app-bar
                :clipped-left="primaryDrawer.clipped"
                app
        >
            <v-app-bar-nav-icon @click.stop="primaryDrawer.model = !primaryDrawer.model"/>
            <v-spacer></v-spacer>
        </v-app-bar>
        <v-content>
            <v-progress-linear :active="$store.state.loading" :indeterminate="true" height="4"></v-progress-linear>
            <v-container fluid>
                <transition mode="out-in" name="fade">
                    <template v-if="!$store.state.loading">
                        <slot></slot>
                    </template>
                </transition>
                <div class="flashpool">
                    <flash-message></flash-message>
                </div>
            </v-container>
        </v-content>
        <v-footer
                :inset="footer.inset"
                app
        >
            <span class="px-4">&copy; {{ new Date().getFullYear() }}</span>
        </v-footer>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import UserService from "../_services/UserService";
    import StompClient from "../_helpers/StompClient";
    import Settings from "../app/Settings";
    import Billing from "../app/Billing";

    export default {
        name: "DefaultLayout",
        components: {Settings, Billing},
        data() {
            return {
                primaryDrawer: {
                    model: null,
                    clipped: false,
                    floating: false,
                    mini: false,
                },
                footer: {
                    inset: false,
                },
                menus: [
                    {title: 'База заявок', icon: 'mdi-format-list-bulleted-square', link: '/'},
                    {title: 'Лист записей', icon: 'mdi-format-list-checks', link: '/appointments', secured: ['ADMIN', 'OPERATOR_MANAGER', 'OPERATOR']},
                    {title: 'Напоминания', icon: 'mdi-calendar-check-outline', link: '/reminders'},
                    {title: 'Статистика', icon: 'mdi-chart-bar', link: '/reports', secured: ['ADMIN', 'OPERATOR_MANAGER', 'OPERATOR', 'PROVIDER']},
                    {title: 'Сотрудники', icon: 'mdi-account-circle', link: '/users', secured: ['ADMIN']},
                    {title: 'Подразделения', icon: 'mdi-account-group', link: '/subdivisions', secured: ['ADMIN']},
                    {title: 'Оплаты', icon: 'mdi-cash-100', link: '/payments', secured: ['PROVIDER']},
                    // {title: 'Отчеты', icon: 'mdi-account', link: '/requests/create'},
                    // {title: 'Лист записи', icon: 'mdi-account-group-outline', link: '/requests/1'},
                ]
            }
        },
        computed: {
            ...mapState(['user']),
            userMenu() {
                let user = this.user;
                return this.menus.filter(function (item) {
                    return !item.secured || item.secured.includes(user.role);
                });
            }
        },
        methods: {
            logout() {
                UserService.logout();
            }
        },
        beforeCreate() {
            this.$vuetify.theme.dark = true
        },
        created() {
            let stompClient = new StompClient(this.user, this.$store);
            stompClient.init();
        }
    }
</script>


